import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function Feature(props) {
  return (
    <section
      className=" bg-cover bg-center py-28"
      style={{
        backgroundImage: `url(${require("../../images/Shop.jpg")})`,
      }}
    >
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-medium text-white mb-8">

        </h1>
        <div className="flex justify-center">
          <Link to="/dashboard/seller">
            <button
              className={`flex mx-auto mt-8 text-purple-600 bg-white border-0 py-2 px-8 focus:outline-none rounded text-xl`}
            >
              Händler werden
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
}

Feature.defaultProps = {
  theme: "indigo",
};

Feature.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default Feature;
