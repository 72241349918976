import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

function Steps(props) {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.2,
  });

  const imageVariants = {
    hidden: { y: 200, opacity: 0 },
    visible: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
        delay: i * 0.2,
      },
    }),
  };

  const openForm = async () => {
    try {
      window.open("https://toyeruuwng1.typeform.com/to/m9XBPNfs");
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <section className="text-gray-600 bg-purple-100 body-font pt-22  ">
      <div className="container px-5 py-24 mx-auto">
        <div className="text-center mb-20">
          <h1 className="sm:text-4xl text-3xl font-medium title-font text-purple-500 mb-4">
            So funktioniert es
          </h1>
          <p className="title-font sm:text-3xl text-2xl mt-4 font-medium text-purple-500">
            Mit Vary kannst du einfach und sicher deine Kleidung vermieten.
          </p>
        </div>
        <div
          className="flex flex-col md:flex-row sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6"
          ref={ref}
        >
          <motion.div
            className="flex flex-col text-center items-center"
            variants={imageVariants}
            animate={inView ? "visible" : "hidden"}
          >
            <motion.img
              src={require("../information/images/SearchUI.png")}
              alt="Logo"
              className="w-7/12"
            />
            <div className="flex-grow">
              <h2 className="text-purple-500 text-xl title-font font-medium mb-3 mt-4">
                1. Teile dein Geschäft
              </h2>
              <p className="leading-relaxed text-base ">
                Hinterlasse die Informationen zu deinem Kleidungsgeschäft
              </p>
            </div>
          </motion.div>
          <motion.div
            className="flex flex-col text-center items-center"
            variants={imageVariants}
            animate={inView ? "visible" : "hidden"}
          >
            <motion.img
              src={require("../information/images/UI.png")}
              alt="Logo"
              className="w-7/12"
            />
            <div className="flex-grow">
              <h2 className="text-purple-500 text-xl title-font font-medium mb-3 mt-4">
                2. Akzeptiere Zahlungen
              </h2>
              <p className="leading-relaxed text-base">
                Ermögliche deinen Kunden online zu zahlen
              </p>
            </div>
          </motion.div>

          <motion.div
            className="flex flex-col text-center items-center"
            variants={imageVariants}
            animate={inView ? "visible" : "hidden"}
          >
            <motion.img
              src={require("../information/images/OrderUI.png")}
              alt="Logo"
              className="w-7/12"
            />
            <div className="flex-grow">
              <h2 className="text-purple-500 text-xl title-font font-medium mb-3 mt-4">
                3. Kontrolliere das Ticket
              </h2>
              <p className="leading-relaxed text-base">
                Weise nach, dass dein Kunde ein gültiges Ticket hat.
              </p>
            </div>
          </motion.div>
        </div>

        <button
          className={`flex mx-auto mt-16 text-white bg-purple-400 border-0 py-2 px-8 focus:outline-none hover:bg-purple-600 rounded text-lg`}
          onClick={openForm}
        >
          Beratungstermin vereinbaren
        </button>
      </div>
    </section>
  );
}

Steps.defaultProps = {
  theme: "indigo",
};

Steps.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default Steps;
