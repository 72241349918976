import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function Footer(props) {
  return (
    <footer className="text-white bg-black body-font">
      <div className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
        <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
          <div className="flex justify-center items-center flex-shrink-0 ">
            <h1 className="  text-xl cursor-pointer">
              <img
                src={require("../images/Vary.png")}
                alt="Logo"
                className="retangle"
                width={100}
                height={45}
              />
            </h1>
          </div>

          <p className="text-sm text-gray-500">
            2023. Alle Rechte vorbehalten.
          </p>
        </div>
        <div className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <nav className="list-none mb-10">
              <li>
                <Link
                  to="/impressum"
                  className="cursor-pointer text-gray-400 hover:text-white"
                >
                  Impressum
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="cursor-pointer text-gray-400 hover:text-white"
                >
                  Kontakt
                </Link>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <nav className="list-none mb-10">
              <li>
                <Link
                  to="/datenschutz"
                  className="cursor-pointer text-gray-400 hover:text-white"
                >
                  Datenschutz
                </Link>
              </li>
              <li></li>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.defaultProps = {
  theme: "indigo",
};

Footer.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default Footer;
