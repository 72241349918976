import axios from "axios";

export const createAbo = async (token, data) =>
  await axios.post(`${process.env.REACT_APP_API}/create-abo`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const allAbos = async () =>
  await axios.get(`${process.env.REACT_APP_API}/abos`);

export const sellerAbos = async (token) =>
  await axios.get(`${process.env.REACT_APP_API}/seller-abo`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteUser = async (token, userId) =>
  await axios.delete(`${process.env.REACT_APP_API}/delete-user/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const cancelOrder = async (token, orderId) =>
  await axios.delete(`${process.env.REACT_APP_API}/cancel-order/${orderId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const read = async (aboId) =>
  await axios.get(`${process.env.REACT_APP_API}/abo/${aboId}`);

export const updateAbo = async (token, data, aboId) =>
  await axios.put(`${process.env.REACT_APP_API}/update-abo/${aboId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const userOrder = async (token) =>
  await axios.get(`${process.env.REACT_APP_API}/user-orders`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const alreadyBooked = async (token, aboId) =>
  await axios.get(`${process.env.REACT_APP_API}/already-booked/${aboId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const searchAbos = async (query) =>
  await axios.post(`${process.env.REACT_APP_API}/search-abos`, query);


