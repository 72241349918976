import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { allAbos } from "../actions/abo";
import SmallCard from "../components/cards/Smallcard";
import Footer from "../components/Footer";
import Faq from "../components/information/Faq";
import Steps from "../components/information/Steps";
import Seller from "../components/information/Seller";
import Profit from "../components/information/Profit";
import axios from "axios";

import CookieBanner from "../components/Cookies";

const Home = ({ history }) => {
  const [state, setState] = useState({
    user: {},
    token: "",
  });

  useEffect(() => {
    setState(JSON.parse(localStorage.getItem("auth")));
  }, []);

  // axios config
  const token = state && state.token ? state.token : "";
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const [abos, setAbos] = useState([]);
  useEffect(() => {
    loadAllAbos();
  }, []);

  const loadAllAbos = async () => {
    let res = await allAbos();
    setAbos(res.data);
  };

  const openForm = async () => {
    try {
      window.open(
        "https://docs.google.com/forms/d/e/1FAIpQLSeN3Az_fPHiDvbHT8jRHWiYOEEjLHz-4eBzt-ibSh0j9gC4Qg/viewform?usp=sf_link"
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <section class="pt-24 pb-5 bg-purple-100">
        <div className="relative px-4 pt-16 mx-auto lg:py-32 md:px-8 xl:px-20 sm:max-w-xl md:max-w-full">
          <div className="max-w-xl mx-auto lg:max-w-screen-xl">
            <div className="mb-16 lg:max-w-lg lg:mb-0">
              <div className="max-w-xl mb-6">
                <div>
                  <h1 class="mb-8 text-4xl font-extrabold leading-none tracking-normal text-gray-900 md:text-6xl md:tracking-tight">
                    <span>Kleidung</span>{" "}
                    <span class="block w-full py-2 text-transparent bg-clip-text leading-12 bg-gradient-to-r from-green-400 to-purple-500 lg:inline">
                      einfach Vermieten
                    </span>{" "}
                    <span>Mit Vary.</span>
                  </h1>
                  <p class="px-0 mb-8 text-lg text-zinc-900 md:text-xl lg:px-24">
                    Für Boutiquen und Kleidungsgeschäfte
                  </p>
                </div>
                <div class="mb-4 space-x-0 md:space-x-2 md:mb-8">
                  <a
                    href="https://toyeruuwng1.typeform.com/to/m9XBPNfs"
                    class="inline-flex items-center justify-center w-full px-6 py-3 mb-2 text-lg text-white bg-green-400 rounded-2xl sm:w-auto sm:mb-0"
                  >
                    Beratungstermin
                    <svg
                      class="w-4 h-4 ml-1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </a>
                  <a
                    href="https://toyeruuwng1.typeform.com/to/m9XBPNfs"
                    class="inline-flex items-center justify-center w-full px-6 py-3 mb-2 text-lg bg-gray-100 rounded-2xl sm:w-auto sm:mb-0"
                  >
                    Zur Warteliste
                    <svg
                      class="w-4 h-4 ml-1"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                      ></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div className="flex justify-center h-full overflow-hidden lg:w-2/3 xl:w-1/2 lg:absolute lg:justify-start lg:bottom-0 lg:right-0 lg:items-end">
              <img
                src={require("../images/Kleidung.png")}
                className="object-cover w-full h-full px-6 

    "
                alt=""
              />{" "}
            </div>
          </div>
        </div>
      </section>
      <Profit />
      <Steps />
      <Seller />
      <Faq />
      <Footer />
    </>
  );
};

export default Home;
