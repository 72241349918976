import React from "react";
import PropTypes from "prop-types";

function SVG(props) {
  return (
    <div>
      <section className="text-white">
        <div className="container px-5 py-24 mx-auto">
          <div className="text-center mb-10">
            <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-purple-400 mb-5">
              Ein paar Fragen, ein paar Antworten.
            </h1>
            <p className="text-base  text-black leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
              Hier findest du Antworten zu den meistgstellten Fragen zu{" "}
              <span className="text-purple-400">Vary</span>
            </p>
          </div>
          <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
            <div className="w-full lg:w-1/2 px-4 py-2">
              <details className="mb-7">
                <summary className="font-semibold  bg-purple-400 rounded-md py-2 px-4 mb-4">
                  Wie funktioniert es?
                </summary>

                <span className="text-black ">
                  Bei Vary kannst deine Kleidung vermieten. Deine Kunden können
                  von dir ausgewählte Kleidungsstücke mieten und anschließend
                  zurückbringen. Wir helfen dir natürlich deinen Account zu
                  erstellen.
                </span>
              </details>
              <details className="mb-7">
                <summary className="font-semibold  bg-purple-400 rounded-md py-2 px-4 mb-4">
                  Wie läuft die Zahlung ab?
                </summary>

                <span className="text-black ">
                  Deine Kunden können mit Apple Pay, Google Pay,
                  Sepa-Lastschrift, Sofort-Überweisung und Kreditkarte zu
                  zahlen.
                </span>
              </details>
            </div>
            <div className="w-full lg:w-1/2 px-4 py-2">
              <details className="mb-7">
                <summary className="font-semibold  bg-purple-400 rounded-md py-2 px-4 mb-4">
                  Wer übernimmt die Reinigung?
                </summary>

                <span className="text-black ">
                  Wir übernehmen die vollständige Reinigung der zurückgegebenen
                  Kleidungsstücke, welche für dich kostenfrei ist.
                </span>
              </details>
              <details className="mb-7">
                <summary className="font-semibold  bg-purple-400 rounded-md py-2 px-4 mb-4">
                  Warum sollte Ich Kleidung vermieten?
                </summary>

                <span className="text-black ">
                  Indem du Kleidung vermietest, kannst du mehr Geld aus deinen
                  Kleidungsstücken verdienen und gleichzeitig Nachhaltigkeit
                  fördern.
                </span>
              </details>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

SVG.defaultProps = {
  theme: "indigo",
};

SVG.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default SVG;
