import Footer from "../components/Footer";

const Datenschutz = () => {
  return (
    <>
      <div className=" flex items-center justify-center py-20  sm:px-6 lg:px-8">
      <div className="flex px-4 flex-col w-full mb-6 mt-4">
          <div>
            <h2 className="mt-10 text-center text-4xl font-extrabold text-gray-900">
              Datenschutzerklärung
            </h2>
          </div>
          <h3 className="pt-3">
            1. Verantwortlicher für die Datenverarbeitung <br />
            <br />
            Vary Clothes, Sprödentalstraße 84 Krefeld <br />
            <br />
            E-Mail: varyyclothes@gmail.com <br />
            <br />
            2. Sammlung und Verarbeitung von Daten <br />
            <br />
            Wir sammeln und verarbeiten die folgenden personenbezogenen Daten:{" "}
            <br />
            - Nutzername <br />
            - E-Mail-Adresse <br />
            - IP-Adresse <br />
            <br />
            Diese Daten werden für die Registrierung von Nutzern auf unserer
            Plattform gesammelt und in unserer MongoDB-Datenbank gespeichert.{" "}
            <br />
            <br />
            3. Nutzung von Daten <br />
            <br />
            Wir verwenden die gesammelten Daten, um Nutzer zu identifizieren,
            ihnen Zugang zu unseren Diensten zu gewähren, unsere Dienste zu
            verbessern und sie über Updates zu unseren Diensten zu informieren.{" "}
            <br />
            <br />
            4. Weitergabe von Daten <br />
            <br />
            Wir geben keine personenbezogenen Daten an Dritte weiter, es sei
            denn, dies ist zur Erbringung unserer Dienstleistungen erforderlich
            oder gesetzlich vorgeschrieben. <br />
            <br />
            <br />
            Im Zusammenhang mit Transaktionen, die über unsere Plattform
            abgewickelt werden, werden wir Ihre Daten an unseren
            Zahlungsanbieter Stripe Payments weitergeben. Die von Stripe
            gesammelten Daten können folgende Informationen enthalten: <br />
            - Name <br />
            - Adresse <br />
            - IBAN <br />
            - Gewählte Zahlungsmittel <br />
            - Betrag von Zahlungen <br />
            Für Händler: <br />
            - Name <br />
            - Adresse <br />
            - IBAN <br />
            - Unternehmensbranche <br />
            - Informationen zu gewerblichen Einahmen und Transaktionen, die auf
            unserer Plattform getätigt wurden <br />
            <br />
            Diese Daten werden auf den Servern von Stripe gespeichert. Für die
            Verarbeitung dieser Daten ist Stripe verantwortlich. Weitere
            Informationen finden Sie in der Datenschutzerklärung von Stripe.{" "}
            <br />
            <br />
            5. Rechte der Nutzer <br />
            <br />
            Nutzer haben das Recht auf Zugang, Berichtigung, Löschung,
            Einschränkung der Verarbeitung, Widerspruch gegen die Verarbeitung
            und Datenübertragbarkeit ihrer personenbezogenen Daten. Nutzer
            können diese Rechte ausüben, indem sie uns unter der oben genannten
            E-Mail-Adresse kontaktieren. <br />
            <br />
            6. Änderungen der Datenschutzerklärung <br />
            <br />
            Wir behalten uns das Recht vor, diese Datenschutzerklärung jederzeit
            zu ändern. Nutzer werden über wesentliche Änderungen per E-Mail
            informiert.
          </h3>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Datenschutz;
