import Home from "./booking/Home";
import Benefits from "./components/information/Benefits";
import Impressum from "./components/Impressum";
import Datenschutz from "./components/Datenschutz";
import Contact from "./components/Contact";
import { Route, Switch } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import Navbar from "./components/Nav";
import { ToastContainer } from "react-toastify";
import { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";

import "antd/dist/antd.min.css";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <ToastContainer />
      <Toaster />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/nachhaltigkeit" component={Benefits} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/impressum" component={Impressum} />
        <Route exact path="/datenschutz" component={Datenschutz} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
