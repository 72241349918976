import React from "react";
import PropTypes from "prop-types";

function Contact(props) {
  return (
    <section className="text-gray-600 body-font relative">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-6 mt-4">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
            Kontakt
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            Du kannst uns unter der folgenden Email erreichen:
          </p>
        </div>
        <div className="lg:w-1/2 md:w-2/3 mx-auto">
          <div className="flex flex-wrap -m-2">
            <p className=" mx-auto leading-relaxed text-base">
              <span className="text-purple-400">varyyclothes@gmail.com</span>
            </p>
            <p className=" mx-auto text-center leading-relaxed">
              Wir versuchen dich innerhalb der nächsten 24 Stunden nach
              Kontaktaufnahme zu kontaktieren.
            </p>
            <div className="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
              <span className="text-purple-400">varyyclothes@gmail.com</span>
              <p className="leading-normal my-5">
                Vary.
                <br />
                2023
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Contact.defaultProps = {
  theme: "indigo",
};

Contact.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default Contact;
