import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isOn, setIsOn] = useState(true); // Initially show navbar
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => ({ ...state }));
  const history = useHistory();

  const logout = () => {
    dispatch({
      type: "LOGOUT",
      payload: null,
    });
    window.localStorage.removeItem("auth");
    history.push("/login");
  };

  return (
    <Transition
      show={isOn}
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="bg-white ">
        <nav className=" fixed w-full z-10 pt-2 transparent">
          <div className="w-full">
            <div className="flex items-center h-18 mt-1 w-full">
              <div className="flex items-center  mx-10  justify-between w-full">
                {" "}
                <div className="flex mt-2 justify-center items-center flex-shrink-0 ">
                  <h1 className="  text-xl cursor-pointer">
                    <a
                      href="/"
                      className="flex title-font font-medium items-center md:justify-start justify-center text-white"
                    >
                      {" "}
                      <img
                        src={require("../images/Vary.png")}
                        alt="Logo"
                        className="retangle"
                        width={100}
                        height={45}
                      />
                    </a>
                  </h1>
                </div>
                <div className="hidden md:block">
                  <div>
                    <ul className="ml-10 flex items-baseline space-x-4">
                      <li>
                        <Link
                          to="/nachhaltigkeit"
                          className="cursor-pointer border-1 border-green-700 hover:border-green-600  px-3 py-2 text-green-700 hover:text-green-600 rounded-md text-sm font-medium "
                        >
                          Nachhaltigkeit
                        </Link>
                      </li>

                      {auth !== null && (
                        <>
                          <Link
                            className="cursor-pointer border-1 border-purple-400 hover:border-purple-400  px-3 py-2 text-purple-500 hover:text-purple-600 rounded-md text-sm font-medium "
                            to="/dashboard"
                          >
                            Profil
                          </Link>
                        </>
                      )}

                      {auth !== null && (
                        <a
                          className="cursor-pointer border-1 border-purple-400 hover:border-purple-400  px-3 py-2 text-purple-500 hover:text-purple-600 rounded-md text-sm font-medium "
                          onClick={logout}
                        >
                          Abmelden
                        </a>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="mr-4 mb-2 flex md:hidden ">
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  type="button"
                  className="bg-purple-400 inline-flex items-center justify-center p-2 rounded-md text-white  hover:bg-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-purple-400 focus:ring-white"
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                >
                  {!isOpen ? (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>

          <Transition
            show={isOpen}
            enter="transition ease-out duration-100 transform"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75 transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="md:hidden" id="mobile-menu ">
              <div>
                <ul className="bg-white px-2 pt-2 pb-3 space-y-1 sm:px-3  ">
                  <li></li>
                  <li>
                    {auth !== null && (
                      <>
                        <Link
                          onClick={() => setIsOpen(!isOpen)}
                          className="cursor-pointer border-1  hover:bg-purple-400 text-black hover:text-white block px-3 py-2 text-center border-purple-400 rounded-md text-base font-medium"
                          to="/dashboard"
                        >
                          Profil
                        </Link>
                      </>
                    )}
                  </li>
                  <li></li>
                  <li>
                    {auth !== null && (
                      <>
                        <Link
                          onClick={() => setIsOpen(!isOpen)}
                          className="cursor-pointer  hover:bg-green-700 text-green-700  hover:text-green-500 block px-3 py-2 text-center 
                           rounded-md text-base font-medium"
                          to="/nachhaltigkeit"
                        >
                          Nachhaltigkeit
                        </Link>
                      </>
                    )}
                  </li>
                  <li>
                    {auth === null && (
                      <>
                        <Link
                          onClick={() => setIsOpen(!isOpen)}
                          className="cursor-pointer  hover:bg-green-700 text-green-700  hover:text-green-500 block px-3 py-2 text-center 
                           rounded-md text-base font-medium"
                          to="/nachhaltigkeit"
                        >
                          Nachhaltigkeit
                        </Link>
                      </>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </Transition>
        </nav>
      </div>
    </Transition>
  );
}

export default Navbar;
