import React from "react";
import PropTypes from "prop-types";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { GiEcology } from "react-icons/gi";
import { IoStorefrontSharp } from "react-icons/io5";

function LightFeatureB(props) {
  return (
    <section className=" body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="text-center mb-20">
          <h1 className="sm:text-3xl  text-2xl font-medium title-font text-gray-900 mb-4">
            Vorteile des Vermietens für Händler
          </h1>

          <div className="flex mt-6 justify-center">
            <div
              className={`w-16 h-1 rounded-full bg-purple-200 inline-flex`}
            ></div>
          </div>
        </div>
        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
          <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
            <div
              className={`w-20 h-20 inline-flex items-center justify-center rounded-full bg-${props.theme}-100 text-purple-500 mb-5 flex-shrink-0`}
            >
              <FaMoneyBillTrendUp className="w-20 h-20" />
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Umsätze steigern
              </h2>
              <p className="leading-relaxed text-purple-500 text-base">
                Steigern Sie ihre Margen pro Kleidungsstück
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
            <div
              className={`w-20 h-20 inline-flex items-center justify-center rounded-full bg-${props.theme}-100 text-purple-500 mb-5 flex-shrink-0`}
            >
              <GiEcology className="w-20 h-20" />
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Nachhaltigkeit fördern
              </h2>
              <p className="leading-relaxed text-purple-500 text-base">
                Transformieren Sie ihr Geschäft zur Nachhaltigkeit
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
            <div
              className={`w-20 h-20 inline-flex items-center justify-center rounded-full bg-${props.theme}-100 text-purple-500 mb-5 flex-shrink-0`}
            >
              <IoStorefrontSharp className="w-20 h-20" />
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Geschäft innovieren
              </h2>
              <p className="leading-relaxed text-purple-500 text-base">
                Erweitern Sie ihr Geschäft mit einem zukunftsorientiertem
                Konzept
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

LightFeatureB.defaultProps = {
  theme: "indigo",
};

LightFeatureB.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default LightFeatureB;
