import React from "react";
import PropTypes from "prop-types";
import Footer from "../Footer";
import { GiWaterRecycling, GiClothes } from "react-icons/gi";
import { TbBuildingFactory } from "react-icons/tb";


function Benefits(props) {
  return (
    <section className="text-white bg-gradient-to-r from-purple-400 to-green-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <main>
          {" "}
          <div className="relative px-6 lg:px-8">
            <div className="mx-auto max-w-2xl py-32 sm:py-36 lg:py-48">
              <div className="text-center">
                <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                  Kleidung vermieten.
                </h1>
                <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                  Unwelt schützen.
                </h1>
              </div>
            </div>
          </div>
        </main>
        <div className="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
          <div
            className={`sm:w-60 sm:h-60 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-${props.theme}-100 text-${props.theme}-500 flex-shrink-0`}
          >
            <GiWaterRecycling size={70} />
          </div>
          <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
            <h2 className="text-white text-lg title-font font-medium mb-4">
              Wasserverbrauch
            </h2>
            <p className="leading-relaxed text-base">
              Jedes neu gekaufte Kleidungsstück bedeutet hohe Mengen an
              Wasserverbrauch. Deshalb ist es wichtig, dass wir uns bewusst
              sind, wie unser Konsumverhalten unsere Umwelt beeinflusst.
            </p>
          </div>
        </div>
        <div className="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
          <div
            className={`sm:w-60 sm:h-60 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-${props.theme}-100 text-${props.theme}-500 flex-shrink-0`}
          >
            <GiClothes size={70} />
          </div>
          <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
            <h2 className="text-white text-lg title-font font-medium mb-4">
              Langlebigkeit
            </h2>
            <p className="leading-relaxed text-base">
              So gut wie jedes fünfte Kleidungsstück verbleibt ungetragen. Hinzu
              kommt, dass selten getragene Kleidungsstücke mit erhaltenem
              Zustand im Müll landen. Eine Möglichkeit, die Langlebigkeit von
              Kleidungsstücken zu erhöhen, wird mit Second Hand erreicht.
            </p>
          </div>
        </div>
        <div className="flex items-center lg:w-3/5 mx-auto sm:flex-row flex-col">
          <div
            className={`sm:w-60 sm:h-60 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-${props.theme}-100 text-${props.theme}-500 flex-shrink-0`}
          >
            <TbBuildingFactory size={70} />
          </div>
          <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
            <h2 className="text-white text-lg title-font font-medium mb-4">
              CO₂
            </h2>
            <p className="leading-relaxed text-base">
              Die Produktion von Kleidung erfordert den Ausstoß von großen
              Mengen an Kohlenstoffdioxid. Von Produktion, Transport und
              Entsorgung werden verständlich viele Ressourcen genutzt. Um diese
              Kette zu entlasten können wir auf nachhaltige Konzepte umsteigen.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
}

Benefits.defaultProps = {
  theme: "indigo",
};

Benefits.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default Benefits;
