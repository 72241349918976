import Footer from "../components/Footer";

const Impressum = () => {
  return (
    <>
      <div className=" flex items-center justify-center py-20  sm:px-6 lg:px-8">
      <div className="flex px-4 flex-col w-full mb-6 mt-4">
          <div>
            <h2 className="mt-10 text-center text-4xl font-extrabold text-gray-900">
              Impressum
            </h2>
          </div>
          <h3 className="">
            Über uns: <br />
            Mit Vary kannst du monatlich Kleidung von Kleidungsgeschäften mieten
            und deinen Kleiderschrank stetig erneuern. Darüber hinaus schonst du
            gleichzeitig die Umwelt, indem du Kleidungsstücke im Konsumkreislauf
            hältst.
          </h3>

          <h4 className="pt-3">
            Angaben gemäß § 5 TMG: <br /> <br />
            Vary Clothes, Sprödentalstraße 84, 47799 Krefeld <br /> <br />
            Handelsregister: Noch nicht im Handelsregister eingetragen <br />{" "}
            <br />
            Registergericht: Amtsgericht Krefeld <br />
            <br /> <br />
            Vertreten durch: Kenan Sen, Bertan Ucar
            <br /> <br />
            Kontakt <br /> <br />
            Telefon: 015754412513 <br /> <br />
            E-Mail: varyyclothes@gmail.com
            <br /> <br /> <br /> <br />
            Haftung für Inhalte
            <br /> <br />
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
            auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
            §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch
            nichtverpflichtet, übermittelte oder gespeicherte fremde
            Informationen zu überwachen oder nach Umständen zu forschen, die auf
            eine rechtswidrige Tätigkeit hinweisen.Verpflichtungen zur
            Entfernung oder Sperrung der Nutzung von Informationen nach den
            allgemeinenGesetzen bleiben hiervon unberührt. Eine diesbezügliche
            Haftung ist jedoch erst ab dem Zeitpunkt derKenntnis einer konkreten
            Rechtsverletzung möglich. Bei Bekanntwerden von
            entsprechendenRechtsverletzungen werden wir diese Inhalte umgehend
            entfernen. ​<br /> <br /> <br /> <br />
            Haftung für Links
            <br /> <br />
            Unser Angebot enthält Links zu externen Websites Dritter, auf deren
            Inhalte wir keinen Einfluss haben.Deshalb können wir für diese
            fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
            verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
            der Seiten verantwortlich. Die verlinktenSeiten wurden zum Zeitpunkt
            der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
            Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine
            permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
            ohne konkrete Anhaltspunkte einerRechtsverletzung nicht zumutbar.
            Bei Bekanntwerden von Rechtsverletzungen werden wir derartige
            Linksumgehend entfernen. ​<br /> <br /> <br /> <br />
            Urheberrecht
            <br /> <br />
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem deutschenUrheberrecht. Die
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung außerhalb derGrenzen des Urheberrechtes bedürfen der
            schriftlichen Zustimmung des jeweiligen Autors bzw.
            Erstellers.Downloads und Kopien dieser Seite sind nur für den
            privaten, nicht kommerziellen Gebrauch gestattet.Soweit die Inhalte
            auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
            Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter
            als solche gekennzeichnet. Sollten Sie trotzdem auf
            eineUrheberrechtsverletzung aufmerksam werden, bitten wir um einen
            entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
            werden wir derartige Inhalte umgehend entfernen.
          </h4>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Impressum;
